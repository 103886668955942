<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Designer Statistics'" :title="'Designer Statistics'">
      <div class="list__buttons"></div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div class="list__date-container">
          <div class="list__dates">
            <div class="list__date">
              <date-picker
                v-model="dateFrom"
                type="date"
                :value-type="'YYYY-MM-DD'"
                :show-second="false"
                :format="'YYYY-MM-DD'"
                :title-format="'YYYY-MM-DD'"
                placeholder="Date from"
              ></date-picker>
            </div>
            <div class="list__date">
              <date-picker
                v-model="dateTo"
                type="date"
                :value-type="'YYYY-MM-DD'"
                :show-second="false"
                :format="'YYYY-MM-DD'"
                :title-format="'YYYY-MM-DD'"
                placeholder="Date to"
              ></date-picker>
            </div>
          </div>
          <div class="list__button">
            <MainButton
              @click.native="getList"
              :buttonDisabled="dateFrom != null && dateTo != null ? false : true"
              >Submit</MainButton
            >
          </div>
        </div>
        <div v-if="templatesStatics">
          <CustomTable
            v-if="templatesStaticsList.length"
            :data="templatesStaticsList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
          >
          </CustomTable>
        </div>
        <div class="list__no-have" v-else>Use filter by date.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />

    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import templatesApi from "~/api/templates";

import "vue2-datepicker/index.css";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";

export default {
  name: "TemplatesStatistics",
  metaInfo: {
    title: "Designer Statistics",
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: false,
        renameColumn: [
          {
            from: "email",
            to: "Email",
          },
          {
            from: "group",
            to: "Group",
          },
          {
            from: "all",
            to: "All",
          },
        ],
        customOrder: ["email", "group", "all"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    ModalError,
    DatePicker,
    MainButton,
    CustomTable,
  },
  computed: {
    ...mapState("templates", [
      "error",
      "loadingProcess",
      "templatesStatics",
      "templatesStaticsList",
    ]),
  },
  mounted() {},
  methods: {
    getList() {
      let url = "";
      url += `?dateFrom=${this.dateFrom}&dateTo=${this.dateTo}`;
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .getTemplatesStatistics(url)
        .then((res) => {
          this.$store.commit("templates/setError", {});
          this.$store.commit("templates/setValidationErrors", {});
          this.$store.commit("templates/setTemplatesStatics", res.data.items);
          const list = Object.values(res.data.items);
          for (let i = 0; list.length > i; i++) {
            list[i].group = list[i].groups[0].name;

            const statuses = Object.keys(list[i].statuses);
            for (let a = 0; statuses.length > a; a++) {
              list[i][statuses[a]] = list[i].statuses[statuses[a]];
              this.tableOptions.renameColumn.push({
                from: statuses[a],
                to: statuses[a].replace(/([A-ZА-Я])/g, " $1"),
              });
              this.tableOptions.customOrder.push(statuses[a]);
            }
          }
          this.$store.commit("templates/setTemplatesStaticsList", list);
        })
        .catch((error) => {
          console.log(error);
          const { data } = error.response;
          this.$store.commit("templates/setError", data);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
    closeModal() {
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header {
  grid-template-columns: 200px 100px repeat(12, 1fr) !important;
}
/deep/.custom-table__row {
  grid-template-columns: 200px 100px repeat(12, 1fr) !important;
}

/deep/.custom-table {
  width: 2000px;
}

/deep/.custom-table__header-item--text {
  text-transform: capitalize;
}
.list {
  &__date-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__dates {
    display: flex;
  }

  &__date {
    padding-right: 20px;
  }
}
</style>
